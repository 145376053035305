import { RouteRecordRaw } from "vue-router";

export const mainRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "대시보드",
    component: () => import("../views/DashboardView.vue"),
  },
  {
    path: "redeems",
    name: "redeems",
    component: () => import("../views/RedeemsView.vue"),
  },
  {
    path: "documents",
    name: "documents",
    children: [
      {
        path: "",
        name: "document-table",
        component: () => import("../views/documents/DocumentTableView.vue"),
      },
      {
        path: "editor/:id?",
        name: "document-editor",
        component: () => import("../views/documents/DocumentEditorView.vue"),
      },
    ],
    component: () => import("../views/DocumentsView.vue"),
  },
  {
    path: "games",
    name: "games",
    children: [
      {
        path: "",
        name: "game-table",
        component: () => import("../views/games/GameTableView.vue"),
      },
      {
        path: "editor/:id?",
        name: "game-editor",
        component: () => import("../views/games/GameEditorView.vue"),
      },
    ],
    component: () => import("../views/GamesView.vue"),
  },
  {
    path: "inquiries",
    name: "inquiries",
    children: [
      {
        path: "",
        name: "inquiry-table",
        component: () => import("../views/inquiries/InquiryTableView.vue"),
      },
      {
        path: "editor/:id?",
        name: "inquiry-editor",
        component: () => import("../views/inquiries/InquiryEditorView.vue"),
      },
      {
        path: ":id",
        name: "inquiry-viewer",
        component: () => import("../views/inquiries/InquiryViewer.vue"),
      },
    ],
    component: () => import("../views/InquiriesView.vue"),
  },
  {
    path: "tokens",
    name: "tokens",
    component: () => import("../views/TokensView.vue"),
  },
  {
    path: "users",
    name: "users",
    component: () => import("../views/UsersView.vue"),
  },
  {
    path: "products",
    name: "products",
    component: () => import("../views/ProductsView.vue"),
  },
  {
    path: "orders",
    name: "orders",
    component: () => import("../views/OrdersView.vue"),
  },
  {
    path: "responses",
    name: "responses",
    component: () => import("../views/ResponsesView.vue"),
    children: [
      {
        path: "",
        name: "response-table",
        component: () => import("../views/responses/ResponseTableView.vue"),
      },
      {
        path: "viewer/:id?",
        name: "response-viewer",
        component: () => import("../views/responses/ResponseView.vue"),
      },
    ],
  },
  {
    path: "transactions",
    name: "transactions",
    component: () => import("../views/TransactionsView.vue"),
  },
  {
    path: "records",
    name: "records",
    component: () => import("../views/RecordsView.vue"),
  },
];
