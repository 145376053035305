import Auth from "@aws-amplify/auth";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import MainView from "../views/MainView.vue";
import { mainRoutes } from "./main";

export async function isLoggedIn() {
  try {
    const sess = await Auth.currentSession();
    console.log(sess);
    return sess.isValid();
  } catch (e) {
    return false;
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "home",
    component: MainView,
    children: mainRoutes,
    beforeEnter: async () => {
      if (await isLoggedIn()) {
        return true;
      } else {
        return "/login";
      }
    },
  },
  {
    path: "/login",
    name: "login",
    beforeEnter: async () => {
      return !(await isLoggedIn());
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginView.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    beforeEnter: isLoggedIn,
    component: () => import("../views/LogoutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
});

export default router;
