import VMdEditor from "@kangc/v-md-editor";
import koKr from "@kangc/v-md-editor/lib/lang/ko-KR";
import "@kangc/v-md-editor/lib/style/base-editor.css";
import "@kangc/v-md-editor/lib/theme/style/vuepress.css";
import Amplify from "aws-amplify";
import "overlayscrollbars/css/OverlayScrollbars.css";
import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./App.vue";
import awsExports from "./aws-exports";
import "./input.css";
import router from "./router";
import githubTheme from "@kangc/v-md-editor/lib/theme/github.js";
import "@kangc/v-md-editor/lib/theme/style/github.css";
import VMdPreview from "@kangc/v-md-editor/lib/preview";
import "@kangc/v-md-editor/lib/style/preview.css";
// highlightjs
import hljs from "highlight.js";
import vfmPlugin from "vue-final-modal";

Amplify.configure(awsExports);

const pinia = createPinia();
const app = createApp(App);

VMdEditor.use(githubTheme, {
  Hljs: hljs,
});
VMdEditor.lang.use("ko-KR", koKr);

VMdPreview.use(githubTheme, {
  Hljs: hljs,
});

app.use(router).use(pinia).use(VMdEditor).use(VMdPreview);
app.use(vfmPlugin);
app.mount("#app");
